import React from "react"
import Layout from "../../components/layout/layout"
import InnerWrapper from "../../components/layout/innerWrapper"
import { Row, Col } from "react-bootstrap"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import SEO from "../../components/seo"

import DIFFERENCE1 from "../../images/about/difference/Difference Photo 1.jpg"
import DIFFERENCE2 from "../../images/about/difference/Difference Photo 2.jpg"
import DIFFERENCE3 from "../../images/about/difference/Difference Photo 3.jpg"
import DIFFERENCE4 from "../../images/about/difference/Difference Photo 4.jpg"
import DIFFERENCE5 from "../../images/about/difference/Difference Photo 5.jpg"

import AboutSidebar from "../../components/layout/about-sidebar"

const WalpoleDifferencePage = () => {
  return (
    <Layout className="main inner-page-with-banner">
      <SEO
        title={`The Walpole Difference | Walpole Outdoors`}
        description={`Walpole Outdoors delivers exceptional outdoor experiences. Our team of experts will help you find the perfect outdoor adventure for your family.`}
      />

      {/* CONTENT GOES HERE */}
      <InnerWrapper>
        <div className="page-hero"></div>

        <Breadcrumb>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item href="/about">About</Breadcrumb.Item>
          <Breadcrumb.Item href="/about/walpole-difference">
            The Walpole Difference
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row className="generalcontent">
          <Col md={3}>
            <AboutSidebar></AboutSidebar>
          </Col>
          <Col md={6}>
            <h1 className="generalheader">The Walpole Difference</h1>
            <h4>
              <strong>Style and craftsmanship beyond your expectations.</strong>
            </h4>

            <div className="mt-5">
              <strong>Low maintenance AZEK cellular vinyl</strong>
              <p>
                Homeowners, landscape architects, and builders alike can’t
                believe how much Walpole’s solid cellular vinyl looks and feels
                like natural wood. The important difference is that advanced
                cellular vinyl is low maintenance – you don’t have to repaint,
                repair, or replace it for years – and your fence and structures
                will look great season after season.
              </p>

              <strong>
                Skilled handcrafting is also available in exotic woods
              </strong>
              <p>
                Should you prefer an installation in natural wood, Walpole will
                apply our age-old skills to custom craft in a hardwood of your
                choice. These exotic woods can include teak, mahogany, ash,
                maple, and walnut. We also continue to offer custom outdoor
                solutions in northern white cedar and western red cedar.
              </p>

              <strong>
                Custom design expertise and professional consultation{" "}
              </strong>
              <p>
                Walpole’s expert staff can work from your professional drawings,
                simple sketches, photographs, or ideas, to create the custom
                fence and outdoor structures that perfectly complement your
                taste, needs, and the architecture of your home or business.
                Within our showroom areas Walpole offers a no-charge service
                where a trained specialist will visit your location, take
                measurements, and provide assistance with styles, design
                choices, and options. Customers located farther afield are
                encouraged to telephone our National Sales professionals who
                will walk you through the process and answer your questions.
                During business hours, please call 1-866-229-2153.
              </p>
              <strong>Engineering and CAD technology</strong>
              <p>
                Our in-house engineering department reviews every fence and
                structure order to ensure manufacturing accuracy. On more
                sophisticated custom work, our designers produce detailed
                drawings so that customers know exactly how their project will
                look when installed.
              </p>
              <strong>Advanced manufacturing</strong>
              <p>
                Walpole Outdoors is the nation’s most experienced company in
                AZEK cellular vinyl fabrication, having spent a decade working
                closely with the nation’s most reputable original equipment
                manufacturers of cellular vinyl trim boards. As a result, we
                apply superior laminating, molding, and finishing techniques to
                every fence and outdoor structure we produce. Our AZEK cellular
                vinyl is shaped, mitered, and routered, using the same methods
                and tools that we have employed for generations when
                handcrafting in natural wood. This approach meets our highest
                standards of symmetry and balance, from the substantial dowels
                and joints, to the solid, aesthetically pleasing proportions.
              </p>

              <strong>One-of-a-kind inline painting and staining</strong>
              <p>
                Walpole’s advanced inline two-coat system is identical for
                cellular PVC and natural wood. We use an in-house airless
                spraying operation which helps the cellular structure of
                cellular vinyl become more uniform and smooth. This, along with
                sanding and drying between each coat, ensures quality paint and
                stain coverage. Our factory finish is superior to coating after
                the fence has been installed and eliminates mess in your yard
                and garden.
              </p>
              <strong>Choices in more than 100 colors</strong>
              <p>
                Walpole Outdoors offers you exceptional flexibility in color
                options, with more than 100 choices in vinyl safe paint from
                Sherwin-Williams. All come with a 25-year warranty against
                cracking, peeling, and blistering.
              </p>
              <strong>Professional installation</strong>
              <p>
                Our trained employees make certain that posts are dug to the
                proper depth and then carefully tamped. Every section is made
                straight and sturdy, is accurately aligned and fastened
                securely. Your grounds and landscaping are always treated with
                care and respect.
              </p>
              <strong>Palletized kit shipping</strong>
              <p>
                Walpole has many years of experience shipping palletized product
                for safe delivery to customers across the country. All kits
                include detailed instructions professionally created by Walpole,
                and we can partner with installation specialists in your area.
              </p>
            </div>
          </Col>
          <Col md={3} >
            <Row >
              <img className="ml-4 " src={DIFFERENCE1} />
            </Row>
            <Row>
              <img className="ml-4 mt-4" src={DIFFERENCE2} />
            </Row>
            <Row>
              <img className="ml-4  mt-4" src={DIFFERENCE3} />
            </Row>
            <Row>
              <img className="ml-4  mt-4" src={DIFFERENCE4} />
            </Row>
            <Row>
              <img className="ml-4  mt-4" src={DIFFERENCE5} />
            </Row>
          </Col>
        </Row>
      </InnerWrapper>
    </Layout>
  )
}

export default WalpoleDifferencePage
